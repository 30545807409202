import React, {Suspense} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Home from './Home';
import Background from './Background';
import './App.css';
const Story = React.lazy(()=>import('./Story'));
function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <TransitionGroup>
          <CSSTransition
            key="location.key"
            classNames="fade"
            timeout={{ enter: 500, exit: 500 }}
          >
            <Suspense fallback={Background}>
            <Switch>
              <Route path="/story/:storyName">
                <Story/>
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
            </Suspense>
          </CSSTransition>
          </TransitionGroup>
      </div>
    </BrowserRouter>
  );
}
export default App;
