import React, {useState, useCallback} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {corsiaverse, shrouded} from './titles.svg.js';
import './Home.css';
import Background from '../Background';
function Home() {
    const [target, setTarget] = useState(null);
    const [showPopup, setPopup] = useState(false);
    const clearBlur = useCallback(()=>setTarget(''), [setTarget]);
    const corsiaverseClasses = classNames('story-select-background', {
        'blur': (target && target !== 'corsiaverse')
    });
    const corsiaverseLogoClasses = classNames('story-select-item-text', {
        'blur': (target && target !== 'corsiaverse')
    })
    const shroudedClasses = classNames('story-select-background', {
        'blur': (target && target !== 'shrouded')
    });
    const shroudedLogoClasses = classNames('story-select-item-text', {
        'blur': (target && target !== 'shrouded')
    });
    const popupClasses = classNames('copy-popup', {
        'visible': showPopup
    });
    const copyIp = useCallback(() => {
        navigator.clipboard.writeText("corsiacraft.com:25565").then(()=>{
            console.log('IP Copied!');
            setPopup(true);
            setTimeout(()=>setPopup(false), 750)
        });
    }, [])
    return (
        <div>
            <nav>
                {//<a href="https://forum.corsiaverse.com">FORUM</a>
                }
                <a href="https://wiki.corsiaverse.com">WIKI</a>
                <span id="ip" onClick={copyIp}>IP|CORSIACRAFT.COM</span>
                <span className={popupClasses}>COPIED</span>
                <a className="s-icon" target="_blank" rel="noopener noreferrer" href="https://discord.gg/hcS3WsDEMq">
                    <img alt="discord" src="/assets/Discord-Logo-White.svg" />
                </a>
            </nav>
            <main role="main">
                <header>
                    <div className="logo">
                        <svg
                            viewBox="0 0 586 116"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M287.683 70.8555H283.033V75.5059L287.683 70.8555Z" fill="#BABABA" stroke="#BABABA"/>
                            <path d="M308.346 75.5059V70.8555H303.695L308.346 75.5059Z" fill="#BABABA" stroke="#BABABA"/>
                            <path d="M283.034 91.5179V96.1683H287.685L283.034 91.5179Z" fill="#BABABA" stroke="#BABABA"/>
                            <path d="M303.695 96.1685H308.346V91.518L303.695 96.1685Z" fill="#BABABA" stroke="#BABABA"/>
                            <path d="M304.254 83.2466L304.604 83.5964L304.258 83.9499L296.134 92.25L295.781 92.6112L295.423 92.2538L287.123 83.9538L286.773 83.604L287.119 83.2505L295.243 74.9504L295.596 74.5892L295.954 74.9466L304.254 83.2466ZM295.6 67.7071L279.707 83.6002L295.6 99.4933L311.493 83.6002L295.6 67.7071Z" fill="#BABABA" stroke="#BABABA"/>
                            <path d="M28.7039 4.56002C32.3519 4.56002 34.9439 4.27202 36.4799 3.69602C38.0159 3.12002 39.1679 2.06402 39.9359 0.528015H44.8319L51.1679 26.592L46.2719 27.888C44.4479 23.088 42.7679 19.44 41.2319 16.944C39.6959 14.448 38.0639 12.72 36.3359 11.76C34.7039 10.8 32.5919 10.32 29.9999 10.32C26.1599 10.32 22.8959 11.808 20.2079 14.784C17.6159 17.664 16.3199 21.264 16.3199 25.584V94.416C16.3199 98.544 17.5199 101.952 19.9199 104.64C22.4159 107.328 25.5359 108.672 29.2799 108.672C33.7919 108.672 37.4399 106.704 40.2239 102.768C43.0079 98.736 44.3999 93.408 44.3999 86.784V84.48H50.5919V84.624C50.5919 95.088 48.6239 102.864 44.6879 107.952C40.8479 112.944 34.8959 115.44 26.8319 115.44C18.4799 115.44 11.9519 112.704 7.2479 107.232C2.54389 101.76 0.191895 94.224 0.191895 84.624V35.376C0.191895 25.296 2.59189 17.664 7.39189 12.48C12.2879 7.20002 19.3919 4.56002 28.7039 4.56002Z" fill="#FFECA1"/>
                            <path d="M84.1124 22.8C91.3924 22.8 97.0724 25.08 101.152 29.64C105.232 34.12 107.272 40.4 107.272 48.48V89.52C107.272 97.92 105.272 104.32 101.272 108.72C97.3524 113.04 91.6324 115.2 84.1124 115.2C76.5124 115.2 70.6724 113 66.5924 108.6C62.5124 104.12 60.4724 97.76 60.4724 89.52V48.48C60.4724 40.16 62.4724 33.8 66.4724 29.4C70.5524 25 76.4324 22.8 84.1124 22.8ZM84.1124 110.4C86.9924 110.4 89.3524 109.32 91.1924 107.16C93.1124 105 94.0724 102.24 94.0724 98.88V39.12C94.0724 35.76 93.1124 33 91.1924 30.84C89.3524 28.68 86.9924 27.6 84.1124 27.6C81.3124 27.6 78.9524 28.72 77.0324 30.96C75.1124 33.12 74.1524 35.84 74.1524 39.12V98.88C74.1524 102.32 75.0724 105.12 76.9124 107.28C78.7524 109.36 81.1524 110.4 84.1124 110.4Z" fill="#FFECA1"/>
                            <path d="M115.829 109.93C118.175 109.93 119.862 109.563 120.889 108.83C121.915 108.097 122.429 106.887 122.429 105.2V40.3C122.429 38.6134 121.915 37.4034 120.889 36.67C119.862 35.9367 118.175 35.57 115.829 35.57V31.5H143.329C157.115 31.5 164.009 39.3833 164.009 55.15C164.009 60.5034 163.202 64.83 161.589 68.13C160.049 71.43 157.335 74.2167 153.449 76.49C156.969 78.7634 159.499 81.5867 161.039 84.96C162.579 88.26 163.349 92.4767 163.349 97.61V99.04C163.349 102.267 164.082 104.907 165.549 106.96C167.015 108.94 168.959 109.93 171.379 109.93H172.589V114H171.379C164.265 114 159.022 112.277 155.649 108.83C152.349 105.31 150.699 99.8834 150.699 92.55V89.47C150.699 86.2434 149.929 83.6034 148.389 81.55C146.922 79.4967 145.015 78.47 142.669 78.47H140.029V74.07H143.329C145.822 74.07 147.802 73.08 149.269 71.1C150.809 69.12 151.579 66.5167 151.579 63.29V46.46C151.579 43.2334 150.845 40.6667 149.379 38.76C147.912 36.8534 145.895 35.9 143.329 35.9H134.749V105.2C134.749 106.887 135.262 108.097 136.289 108.83C137.315 109.563 139.002 109.93 141.349 109.93V114H115.829V109.93Z" fill="#FFECA1"/>
                            <path d="M192.728 42C190.462 42 188.462 42.8667 186.728 44.6C185.062 46.2667 184.228 48.2667 184.228 50.6C184.228 52.4667 184.662 54.3334 185.528 56.2C186.462 58 187.962 60.0667 190.028 62.4L200.128 74.2C202.995 77.5334 205.195 80.4334 206.728 82.9C208.328 85.3 209.428 87.6334 210.028 89.9C210.695 92.1 211.028 94.5667 211.028 97.3C211.028 100.567 210.195 103.533 208.528 106.2C206.862 108.867 204.628 111 201.828 112.6C199.028 114.2 195.928 115 192.528 115C190.062 115 188.062 114.767 186.528 114.3C184.995 113.833 183.762 113.367 182.828 112.9C181.962 112.433 181.195 112.2 180.528 112.2C179.862 112.2 179.228 112.4 178.628 112.8C178.028 113.133 177.662 113.533 177.528 114H174.028V92.8H177.428C178.362 98.1334 180.028 102.333 182.428 105.4C184.828 108.4 187.628 109.9 190.828 109.9C193.628 109.9 195.828 108.967 197.428 107.1C199.095 105.233 199.928 102.7 199.928 99.5C199.928 96.9 199.295 94.3 198.028 91.7C196.762 89.0334 194.728 86.1 191.928 82.9L183.528 73.2C180.062 69.1334 177.628 65.7 176.228 62.9C174.828 60.0334 174.128 57 174.128 53.8C174.128 51 174.962 48.4 176.628 46C178.295 43.6 180.462 41.6667 183.128 40.2C185.862 38.7334 188.762 38 191.828 38C194.362 38 196.162 37.8 197.228 37.4C198.295 37 199.095 36.2667 199.628 35.2H203.028L207.428 53.3L204.028 54.2C202.762 50.8667 201.595 48.3334 200.528 46.6C199.462 44.8667 198.328 43.6667 197.128 43C195.995 42.3334 194.528 42 192.728 42Z" fill="#FFECA1"/>
                            <path d="M216.128 110.67C218.048 110.67 219.428 110.37 220.268 109.77C221.108 109.17 221.528 108.18 221.528 106.8V53.7C221.528 52.32 221.108 51.33 220.268 50.73C219.428 50.13 218.048 49.83 216.128 49.83V46.5H237.008V49.83C235.088 49.83 233.708 50.13 232.868 50.73C232.028 51.33 231.608 52.32 231.608 53.7V106.8C231.608 108.18 232.028 109.17 232.868 109.77C233.708 110.37 235.088 110.67 237.008 110.67V114H216.128V110.67Z" fill="#FFECA1"/>
                            <path d="M248.802 107.115C248.575 108.305 248.887 109.24 249.737 109.92C250.644 110.543 252.032 110.855 253.902 110.855V114H239.707V110.855C241.35 110.855 242.569 110.572 243.362 110.005C244.155 109.438 244.694 108.475 244.977 107.115L257.727 49.06H261.807L274.982 107.285C275.265 108.702 275.747 109.665 276.427 110.175C277.164 110.628 278.382 110.855 280.082 110.855V114H260.362V110.855C262.402 110.855 263.819 110.6 264.612 110.09C265.462 109.523 265.745 108.617 265.462 107.37L263.677 98.785H250.587L248.802 107.115ZM251.352 95.385H262.912L257.302 68.27L251.352 95.385Z" fill="#FFECA1"/>
                            <path d="M333.488 49.4C335.641 49.4 337.171 49.23 338.078 48.89C338.985 48.55 339.664 47.9267 340.118 47.02H343.008L346.748 62.405L343.858 63.17C342.781 60.3367 341.79 58.1834 340.883 56.71C339.976 55.2367 339.013 54.2167 337.993 53.65C337.029 53.0834 335.783 52.8 334.253 52.8C331.986 52.8 330.06 53.6784 328.473 55.435C326.943 57.135 326.178 59.26 326.178 61.81V102.44C326.178 104.877 326.886 106.888 328.303 108.475C329.776 110.062 331.618 110.855 333.828 110.855C336.491 110.855 338.645 109.693 340.288 107.37C341.931 104.99 342.753 101.845 342.753 97.935V96.575H346.408V96.66C346.408 102.837 345.246 107.427 342.923 110.43C340.656 113.377 337.143 114.85 332.383 114.85C327.453 114.85 323.6 113.235 320.823 110.005C318.046 106.775 316.658 102.327 316.658 96.66V67.59C316.658 61.64 318.075 57.135 320.908 54.075C323.798 50.9584 327.991 49.4 333.488 49.4Z" fill="#FFECA1"/>
                            <path d="M351.794 110.3C353.928 110.3 355.461 109.967 356.394 109.3C357.328 108.633 357.794 107.533 357.794 106V47C357.794 45.4667 357.328 44.3667 356.394 43.7C355.461 43.0333 353.928 42.7 351.794 42.7V39H376.794C389.328 39 395.594 46.1667 395.594 60.5C395.594 65.3667 394.861 69.3 393.394 72.3C391.994 75.3 389.528 77.8334 385.994 79.9C389.194 81.9667 391.494 84.5334 392.894 87.6C394.294 90.6 394.994 94.4334 394.994 99.1V100.4C394.994 103.333 395.661 105.733 396.994 107.6C398.328 109.4 400.094 110.3 402.294 110.3H403.394V114H402.294C395.828 114 391.061 112.433 387.994 109.3C384.994 106.1 383.494 101.167 383.494 94.5V91.7C383.494 88.7667 382.794 86.3667 381.394 84.5C380.061 82.6334 378.328 81.7 376.194 81.7H373.794V77.7H376.794C379.061 77.7 380.861 76.8 382.194 75C383.594 73.2 384.294 70.8334 384.294 67.9V52.6C384.294 49.6667 383.628 47.3334 382.294 45.6C380.961 43.8667 379.128 43 376.794 43H368.994V106C368.994 107.533 369.461 108.633 370.394 109.3C371.328 109.967 372.861 110.3 374.994 110.3V114H351.794V110.3Z" fill="#FFECA1"/>
                            <path d="M414.382 105.09C414.089 106.63 414.492 107.84 415.592 108.72C416.766 109.527 418.562 109.93 420.982 109.93V114H402.612V109.93C404.739 109.93 406.316 109.563 407.342 108.83C408.369 108.097 409.066 106.85 409.432 105.09L425.932 29.96H431.212L448.262 105.31C448.629 107.143 449.252 108.39 450.132 109.05C451.086 109.637 452.662 109.93 454.862 109.93V114H429.342V109.93C431.982 109.93 433.816 109.6 434.842 108.94C435.942 108.207 436.309 107.033 435.942 105.42L433.632 94.31H416.692L414.382 105.09ZM417.682 89.91H432.642L425.382 54.82L417.682 89.91Z" fill="#FFECA1"/>
                            <path d="M458.742 109.56C461.302 109.56 463.142 109.16 464.262 108.36C465.382 107.56 465.941 106.24 465.941 104.4V33.6C465.941 31.52 465.421 30.16 464.382 29.52C463.422 28.8 461.542 28.44 458.742 28.44V24H494.142C495.982 24 497.302 23.68 498.102 23.04C498.902 22.32 499.421 21.12 499.661 19.44H503.742L509.022 41.16L504.941 42.24C503.581 38.4 502.222 35.52 500.862 33.6C499.582 31.68 497.902 30.4 495.822 29.76C493.822 29.12 491.022 28.8 487.422 28.8H479.382V66.6H482.502C488.022 66.6 490.782 63.72 490.782 57.96H495.222V80.04H490.782C490.782 77 490.102 74.8 488.742 73.44C487.462 72.08 485.382 71.4 482.502 71.4H479.382V104.4C479.382 106.24 479.942 107.56 481.062 108.36C482.182 109.16 484.022 109.56 486.582 109.56V114H458.742V109.56Z" fill="#FFECA1"/>
                            <path d="M532.399 108.672C535.471 108.672 537.679 108.192 539.023 107.232C540.367 106.272 541.039 104.688 541.039 102.48V11.328H539.167C535.327 11.328 532.159 11.904 529.663 13.056C527.263 14.208 525.199 16.32 523.471 19.392C521.839 22.368 520.207 26.688 518.575 32.352L513.247 31.056L519.583 0.672014H524.479C524.863 2.78402 525.487 4.22402 526.351 4.99202C527.311 5.66402 528.895 6.00002 531.103 6.00002H567.967C570.175 6.00002 571.759 5.61602 572.719 4.84802C573.679 4.08002 574.303 2.68802 574.591 0.672014H579.487L585.823 31.056L580.495 32.352C578.863 26.688 577.183 22.368 575.455 19.392C573.823 16.32 571.807 14.208 569.407 13.056C567.007 11.904 563.839 11.328 559.903 11.328H557.167V102.48C557.167 104.688 557.839 106.272 559.183 107.232C560.527 108.192 562.735 108.672 565.807 108.672V114H532.399V108.672Z" fill="#FFECA1"/>
                        </svg>
                    </div>
                </header>
                <section id="stories" className="story-select" onMouseLeave={clearBlur}>
                    <Link to="/story/corsiaverse">
                        <figure
                            id="corsiaverse"
                            className="story-select-item"
                            onMouseEnter={()=>setTarget('corsiaverse')}
                        >
                            <img
                                id="corsiaverse-text"
                                className={corsiaverseLogoClasses}
                                src={`data:image/svg+xml;base64, ${btoa(corsiaverse)}`}
                                alt="CorsiaVerse"
                            />
                            <img
                                className={corsiaverseClasses}
                                src="/assets/corsiaverse_bg.png"
                                alt="CorsiaVerse Background"
                            />
                        </figure>
                    </Link>
                    <hr />
                    <Link to="/">
                        <figure
                            id="shrouded"
                            className="story-select-item"
                            onMouseEnter={()=>setTarget('shrouded')}
                        >
                            <img
                                id="shrouded-text"
                                className={shroudedLogoClasses}
                                src={`data:image/svg+xml;base64, ${btoa(shrouded)}`}
                                alt="The Shrouded City"
                            />
                            <img
                                className={shroudedClasses}
                                src="/assets/shrouded_bg.png"
                                alt="The Shrouded City Background"
                            />
                        </figure>
                    </Link>
                </section>
                <section id="about">
                    <div className="inner">
                        <h1>ABOUT US</h1>
                        <div className="text">
                            <p>
                                CorsiaCraft started as the first use of <a href="https://wiki.corsiaverse.com">'The CorsiaVerse'</a>,<br/>
                                a fictional science-fantasy and steampunk/vernian themed original universe founded by Micsma and Squid Empire.<br/>
                                The CorsiaVerse's lore alongside Squid's 'Steamy' texture-pack birthed the first glance into a mysterious unknown world with strange technologies, religious ideologies, and political class struggles.
                            </p>
                            <p>
                                {`
                                    Over the server's now ${new Date().getFullYear() - new Date(2012,11,7).getFullYear()} year old history, our focus has always been on nurturing these themes alongside consequences of radical changes to the lives of the inhabitants within the nations of the known world.\
                                    With the hard work and dedication of many of our old team members, new renditions of the CorsiaVerse are now taking form including the 'ShroudedCity' which will soon be featured on CorsiaCraft.\
                                `}
                            </p>
                            <p>
                                If you're interested in joining our role-play community, be sure to checkout our <a href="https://discord.gg/hcS3WsDEMq">discord</a>!
                            </p>
                            <p>
                                For more information about the CorsiaVerse,<br/> and how you can create your own edition of this creative commons universe,
                                visit the CorsiaVerse <a href="https://wiki.corsiaverse.com">website</a>.
                            </p>
                        </div>
                    </div>
                </section>
                <section id="join">
                    <div className="inner">
                        <h1>WANT TO GET INVOLVED?</h1>
                        <div className="text">
                            <p>We're currently looking for new team members who can help us get our new features up and running!</p>
                            <p>Currently there are openings for game-play and resource balancers, plugin maintainers, and community managers</p>
                            <p>If you are interested in joining the CorsiaCraft team and...</p>
                            <ul>
                                <li>Can commit a few hours a week</li>
                                <li>Have previous experience with relevant projects to the position</li>
                                <li>Are able to participate in voice staff meetings on our <a href="https://discord.gg/hcS3WsDEMq">discord</a></li>
                            </ul>
                            <p>Contact one of the Admins!</p>
                        </div>
                    </div>
                </section>
            </main>
            <Background />
        </div>
    );
}
export default Home;