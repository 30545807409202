import React from 'react';
import './Background.css';
const Background = () => {
    return (
        <div className="fixed-bg">
            <img className="foreground" src="/assets/foreground.png" alt="foreground" />
            <video
                className="bg-video"
                poster="/assets/foreground.png"
                autoPlay
                loop
                muted
            >
                <source src="/assets/Embers_1_1.webm" type="video/webm"/>
                <source src="/assets/Embers_1_1.mp4" type="video/mp4"/>
            </video>
        </div>
    )
}
export default Background;